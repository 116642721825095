body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #888;
    background-color: #000;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
    font-family: 'Kreon', serif;
    font-weight: 700; 
}

.navH {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(67, 135, 255, 1);
}

.inlineContainer {
    display: flex; 
    align-items: center; 
    gap: 30px;
    width: 100%;
    position: fixed; 
    top: 0; 
    background-color: #000; 
    z-index: 1000; 
    padding: 20px 0; 
    text-decoration: none;
}

.logoContainer, .logoContainerM {
    margin-right: auto;
    align-items: center;
}

.logoContainer {
    margin-left: 53px;
}

.logoContainerM {
    margin-left: 35px;
}

.toggleContainer {
    margin-right: 53px;
}

.holdHeaders {
    margin-left: auto;
    width: 600px;
    font-size: 16px;
    letter-spacing: 2px;
    display: flex; 
    align-items: center; 
    margin-right: 40px;
    gap: 57px;
}

.holdHeaders a {
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
}

.activeTab {
    color: #FF7843;
    position: relative;
    text-shadow: 0 0 10px #FF7843;
    text-decoration: underline;
}


.activeTab::after {
    content: ""; /* Pseudo-element to create the underline */
    position: absolute;
    bottom: -15px; /* Adjust distance between text and line */
    left: 50%; /* Start at the center */
    transform: translateX(-50%); /* Center the line */
    width: 130%; /* Adjust width of the line */
    height: 1.2px; /* Line thickness */
    background: linear-gradient(90deg, rgba(255, 120, 67, 0) 0%, rgb(255, 120, 67) 50%, rgba(255, 120, 67, 0) 100%);
    box-shadow: 0px 0px 8px #FF7843; /* Adds the glowing effect */
    border-radius: 40px; /* Softens the edges of the line */
    cursor: default;
}

.inactiveTab {
    color: #4387FF;
    text-decoration: none;
    transition: color 0.3s ease;
}

.inactiveTab:hover {
    color: #0e3f93;
    text-decoration: none;
}

.mobileDropdown {
    display: flex;
    align-items: center;
    margin-right: 10vw;
}
  
.mobileDropdownLink {
    font-size: 16px;
    font-family: 'Kreon', serif;
    font-weight: 300;
    color: rgba(67, 135, 255, 1);
    margin-right: 8px;
}

.menu-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 8px solid #ff6f61; 
    transform: rotate(90deg); 
}

.homeHero {
    height: 75vh;
    width: 100vw;
    background-color: rgb(0, 0, 0);
    margin-bottom: 0px;
    z-index: 0;
    position: relative; /* Creates positioning context for the circle */
    overflow: hidden;   /* Ensures circle does not "leak" into other sections */
  }

.homeHeroLeftDiv {
    color: white;
    z-index: 5;
    padding-left: 15vw;
    padding-top: 12vh;
}

.homeHeroRightDiv {
    z-index: 5;
    padding-right: 15vw;
    padding-top: 12vh;
}

.homeHeroContainer {
    display: flex; /* Align children (divs) in a row */
    justify-content: space-between; /* Space evenly between the two divs */
    align-items: center; /* Vertically align them in the center */
    width: 100%; /* Ensure full width */
    margin-top: 70px; /* Keeps consistent spacing */
  }
  

.nameHeroLeft {
    color: rgba(255, 255, 255, 0.43);
    font-family: "Jura", sans-serif;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.blueHeader {
    color: rgb(67, 127, 230);
}

.mainHeaderHeroleft{
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 50px;
}

.holdHeaderHeroLeft {
    width: 500px;
}

.arrowLink{
    padding-left: 20px;
}

.arrowLink a {
    position: relative;
    color: #4387FF; 
    font-family: "Kreon", sans-serif; 
    font-size: 16px; 
    text-decoration: none;
    margin-right: 60px; 
    font-weight: 300;
    letter-spacing: 1px;
    transition: color 0.3s ease;
    z-index: 3;
  }
  
  .arrowLink a::after {
    content: "→"; 
    position: absolute;
    right: -25px; 
    color: rgba(255, 120, 67, 1); 
    transition: transform 0.3s ease;
    z-index: 3;
  }
  
  .arrowLink a:hover {
    color: #7eb3ff; 
  }
  
  .arrowLink a:hover::after {
    transform: translateX(5px); 
  }

  .holdSocials {
    display: flex; 
    gap: 30px;
    margin-top: 120px;
  }

  .holdSocials a {
    z-index: 5;
  }

  .meIntro {
    position: relative;
    color: white;
    font-size: 36px;
  }

  .blurredCircle {
    position: absolute;
    border-radius: 50%; /* Makes it a perfect circle */
    background: radial-gradient(
      circle, 
      rgba(22, 22, 230, 0.3) 0%,   /* Center color */
      rgba(64, 64, 169, 0.1) 50%,  /* Mid-point fade */
      transparent 100%             /* Fades to transparent at the edges */
    );
    top: 50%;       /* Center vertically relative to .homeHero */
    left: 50%;      /* Center horizontally relative to .homeHero */
    transform: translate(-50%, -35%);
    z-index: 1;     /* Ensure it's rendered above .homeHero but below .meHomeSection */
    height: 100%;   /* Adjust size for desired clipping effect */
    width: 100%;
  }

  .mouseDown {
    position: absolute;
    left: 50%; 
    top: 70%;
    transform: translate(0%, -90px);
  }


  .imageContainer {
    position: relative; 
    padding-left: 15vw;
    width: 400px;
    height: auto; 
  }
  
  .mainImage {
    width: 100%; 
    height: auto;
  }
  
  .overlayImage {
    position: absolute; 
    top: -17%; 
    left: 30%; 
    width: 70%; 
    height: auto; 
    pointer-events: none; 
  }

  .meHomeSection {
    height: 50vh;
    width: 100vw;
    z-index: -1;
    background-color: rgb(0, 0, 0);
    padding: 0px;
    margin-top: 0px;  

    background-image: url('/Users/williamburroughs/Desktop/personal_website/src/images/MeHomeHeader.svg'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    position: relative;
    }

    .holdMe {
        margin-left: 15vw;
        display: flex; 
    }

    .holdIntro {
        margin-left: 14vw;
        margin-top: 3.3vh;
    }

    .orangeLine {
        position: absolute;
        width: 100px;
        height: 3px;
        background-color: #FF7843;
        margin-left: 34vw;
        margin-top: 15vh;
    }

    .textIntro{
        color: white;
        font-family: "Jura", sans-serif;
        position: absolute;
        margin-left: 34vw;
        margin-top: 19vh;
        width: 600px;
    }

    .homeProjectsSection{
        height: 120vh;
        width: 100vw;
        overflow: hidden;
        align-items: center;
    }

    .blurProjects {
        position: absolute;
        border-radius: 20%; 
        background: radial-gradient(
          circle, 
          rgba(22, 22, 230, 0.25) 0%,   /* Center color */
          rgba(64, 64, 169, 0.15) 50%,  /* Mid-point fade */
          transparent 100%             /* Fades to transparent at the edges */
        );
        margin-top: -40vh;
        margin-left: 10vw;
        align-content: center;
      }

    .latestProject {
        display: flex;
        width: 100vw;
        justify-content: center;
    }

    #projDesk {
        margin-top: 30vh;
    }

    .projectTitle {
        color: white;
        font-size: 24px;
        margin: 50px;
    }

    .holdProject {
        border-radius: 15px; 
        border: 1px solid #4387FF; 
        text-align: center;
        z-index: 4;
    }

    .holdProject:hover {
        transform: scale(1.05); /* Slight scale on hover */
        cursor: pointer;
      }

    .projectRow {
        display: flex; 
        flex-direction: row; 
        justify-content: space-around; 
        align-items: center; 
        padding: 0 20px; 
        margin-top: 30px;
    }

    .letsWorkTogether {
        margin-top: 30vh;
        display: flex;
        width: 100vw;
        height: 45vh;
        background-color: #030915;

        background-image: url('/Users/williamburroughs/Desktop/personal_website/src/images/LetsWorkBackground.svg'); 
        background-size: cover; 
        background-position: center; 
        background-repeat: no-repeat; 
        position: relative; 

        clip-path: inset(0,0,0,0); /* Extremely useful only shows content in div that should be there */
    }

    .letsWorkLeftDiv{
        margin-top: -15vh;
        margin-left: 15vw;

        /* Below used to place items in same row */
        display: flex; 
        flex-direction: row; 
        justify-content: space-around; 
        align-items: center; 
        gap: 20px;
    }

    #letWork{
        margin-left: -18vw;
        margin-top: 7vw;
    }

    .holdLineText {
        position: absolute;
        display: flex; 
        flex-direction: column; 
        left: -19vw;
        top: 3vw;
    }

    .contactMeHolder {
        display: block; 
        position: static; 
        z-index: auto; 
        width: 100%; 
        height: 120vh; 
        overflow: hidden;
        background-color: #080810;

    }

    .blueBlot {
        border-radius: 50%; 
        background: radial-gradient(
          circle, 
          rgba(22, 22, 230, 0.15) 0%,   
          rgba(64, 64, 169, 0.03) 50%,  
          transparent 100%             
        );
        position: absolute;
    }

    #blueBlot1 {
        background: radial-gradient(
            circle, 
            rgba(22, 22, 230, 0.10) 0%,   
            rgba(64, 64, 169, 0.01) 50%,  
            transparent 100%             
          );
    }

    #blueBlot2 {
        background: radial-gradient(
            circle, 
            rgba(22, 22, 230, 0.04) 0%,   
            rgba(64, 64, 169, 0.01) 50%,  
            transparent 100%             
          );
    }

    #blueBlot3 {
        background: radial-gradient(
            circle, 
            rgba(16, 84, 233, 0.05) 0%,   
            rgba(45, 132, 253, 0.01) 50%,  
            transparent 100%             
          );
    }

    .blurHolder {
        position:relative;
        clip-path: inset(0);
        height: 100%; 
        width: 100%;
    }

    .holdForm {
        position: relative;
        margin-top: -100vh;
    }

    /* Form CSS */

    .form-element {
        position: relative;
        margin: 10px 0;
        width: 100%;
      }
      
      .form-input {
        width: 80%;
        padding: 10px 0px;
        font-size: 16px;
        background-color: transparent;
        border: 0px;
        border-bottom: 2px solid #152B52;
        border-radius: 0%;
        outline: none;
        color: #8CAADF;
        transition: border-color 0.3s;
      }
      
      .form-input:focus {
        border-color: #007bff;
      }
      
      .form-label {
        position: absolute;
        top: 50%;
        left: 0px;
        font-size: 16px;
        color: #1B386D;
        pointer-events: none;
        transform: translateY(-50%);
        transition: all 0.2s ease;
      }
      
      .form-input:focus + .form-label,
      .form-input:not(:placeholder-shown) + .form-label {
        top: 0;
        left: 0px;
        font-size: 12px;
        color: #007bff;
      }

      /* End form css */

      input:-webkit-autofill {
        background-color: transparent !important; /* Reset background */
        color: inherit !important; /* Inherit text color */
        transition: background-color 5000s ease-in-out 0s; /* Hack to reset background */
      }

      /* Slider */

      /* Slider.css */


    .slider-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px;
    }
  
  /* Input Slider */
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 70%;
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(to right, #FF7843 0%, #FF7843 var(--progress), #001336 var(--progress), #001336 100%);
    outline: none;
    cursor: pointer;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background: #001336; /* Thumb color */
    border: 5px solid #FF7843; /* Outer border matches the bar's progress color */
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }
  
  .slider::-moz-range-thumb {
    width: 40px;
    height: 40px;
    background: #001336;
    border: 5px solid #f90;
    border-radius: 50%;
    cursor: pointer;
  }
  
  /* Value Display */
  .slider-value {
    font-size: 25px;
    font-weight: bold;
    color: #FF7843;
    min-width: 80px;
    font-family: "Jura", sans-serif;
    padding-left: 55px;
  }
  
  /* Label */
  .slider-label {
    font-size: 16px;
    color: #1B386D;
  }

  .arrowLink{
    padding-left: 20px;
}

/* Apply the same styling to button */
.buttonLink {
    position: relative;
    color: #4387FF; 
    font-family: "Kreon", sans-serif; 
    font-size: 16px; 
    text-decoration: none;
    margin-right: 60px; 
    font-weight: 300;
    letter-spacing: 1px;
    transition: color 0.3s ease;
    z-index: 3;
    background-color: transparent;
    border: none;
  }
  
  .buttonLink::after {
    content: "→"; 
    position: absolute;
    right: -25px; 
    color: rgba(255, 120, 67, 1); 
    transition: transform 0.3s ease;
    z-index: 3;
  }
  
  .buttonLink:hover {
    color: #7eb3ff; 
  }
  
  .buttonLink:hover::after {
    transform: translateX(5px); 
  }

  .footerDektopHolder {
    display: block; 
    position: static; 
    z-index: auto; 
    width: 100%; 
    height: 40vh; 
    overflow: hidden; 
    

    background-image: url('/Users/williamburroughs/Desktop/personal_website/src/images/BackgroundFooter.svg'); 
    background-size: 100%; 
    background-position: center; 
    background-repeat: no-repeat; 

    margin-top: -7.6vh;

  }